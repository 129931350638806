// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';

function Header() {
  return (
    <header className="header">
      {/* <div className="menu-icon">☰</div> */}
      <nav className="social-nav">
        <a href="https://linkedin.com/in/williamhuynh1" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a href="https://twitter.com/william_huynh" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
        <a href="https://noplaybook.beehiiv.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faNewspaper} /></a>

      </nav>
    </header>
  );
}

export default Header;
