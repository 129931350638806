// src/components/ContactPage.js
import React, { useState } from 'react';

function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you would handle the form submission, e.g., sending the data to an API or an email.
    console.log(formData);
    alert("Form submitted. We'll be in touch soon.");
  };

  return (
    <div className='contactpage-wrapper'>
      <h1>WILLIAM HUYNH</h1>

      <h2>Contact Me</h2>

      <br/>
      <p>team@williamhuynh.com</p>
      <br />
      {/* <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
        </div>
        <button type="submit">Submit</button>
      </form> */}
    </div>
  );
}

export default ContactPage;
