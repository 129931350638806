import React from 'react';

const data = [
    {
        misconception: "AI implemented and managed in the same manner as software is adequately robust.",
        reality: "AI systems require continuous monitoring, updating, and retraining to remain effective and secure. Unlike traditional software, AI models can drift from their initial performance benchmarks over time due to changes in the data they're processing or the environment in which they operate. This necessitates a more dynamic approach to maintenance and management."
    },
    {
        misconception: "AI demonstrations are easy; production is hard.",
        reality: "While AI demonstrations and proof-of-concepts can be impressive, scaling these solutions to production environments presents significant challenges. Issues such as data quality, infrastructure scalability, model interpretability, and integration with existing systems need to be addressed. The leap from a controlled demonstration to a live, operational system can be substantial."
    },
    {
        misconception: "AI use-cases may seem valuable, but few can actually be realised.",
        reality: "Many AI use-cases are indeed valuable and can be realised; however, the success of these applications depends heavily on aligning them with specific, well-defined problems, and applying appropriate AI methodologies. The gap often lies not in the value of the use-cases themselves but in the complexity of implementation and integration into existing workflows and systems."
    },
    {
        misconception: "More data always leads to better AI performance.",
        reality: "While data is crucial for training AI models, the quality of the data is often more important than the quantity. Models trained on large volumes of irrelevant or poor-quality data can perform worse than those trained on smaller, high-quality datasets."
    },
    {
        misconception: "AI can replace human decision-making entirely.",
        reality: "AI can augment and enhance human decision-making by processing and analysing data at scales and speeds unattainable by humans. However, AI lacks the nuanced understanding and ethical considerations inherent to human judgement. Successful applications of AI involve collaboration between humans and machines, leveraging the strengths of each."
    },
    {
        misconception: "AI technologies are a distant future.",
        reality: "AI is already embedded in many aspects of daily life and business operations, from recommendation systems in e-commerce and streaming services to automated customer support and fraud detection. The technology is evolving rapidly, and its applications are expanding."
    },
  // Add the rest of your data here following the same format
];

function Misconception() {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Misconception</th>
            <th>Reality</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.misconception}</td>
              <td>{item.reality}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Misconception;
