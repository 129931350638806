// src/components/HomePage.js
import React from 'react';
import ContactPage from '../components/ContactPage';
import AIMisconceptions from '../components/AIMisconceptions';
import Services from '../components/Services';
import LogosSection from './LogosSection';



function HomePage() {
  return (
    <div className='home-container'>

        <div className="home-hero-section">

            <div className='home-content-wrapper'>


                <h1>WILLIAM HUYNH</h1>
                <h3>Fractional Artificial Intelligence and Digital Transformation Officer</h3>
                <p>Hi I'm William Huynh. With so much happening in technology and Artificial Intelligence, are you are feeling overwhelmed? Or you can't seem to make sense of what's hype and what it means for your business? </p>
                <br />
                <p>Whether you are an ambitious small business wanting to benefit from AI technologies, or you are a large business wanting to ensure you are scaling AI effectively and efficiently, I'm here to help you transform what you do with AI.</p>

            </div>
            <div className='home-button'>
                <a href="#contactform"><button>Contact Me</button></a>
            </div>

        </div>

        <div className="home-section-2">
                <h2>Falling into these AI traps can lead to wasted effort, time, and investment</h2>
                <AIMisconceptions />
        </div>

        <div className="home-section-3">
                <h2>How I could help</h2>
                <Services />

        </div>

        {/* <div className="home-section-4">
            <h2>Clients I've previously supported</h2>
            <LogosSection />
        </div> */}


        <div className="home-section-5" id="contactform">
            <h2>Contact Me</h2>
            <br/>
            <p>team@williamhuynh.com</p>
            <br />
        </div>

    </div>
    
  );
}

export default HomePage;
