// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Importing Link from react-router-dom


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-nav">
        
        <Link to="/">BUSINESS WORK</Link>
        <Link to="/creates">LATEST CONTENT</Link>
        <Link to="/contact">CONTACT</Link>
      </div>
    </footer>
  );
}

export default Footer;
