// src/components/CreatesPage.js
import React, { useEffect } from 'react';

function RedirectPage() {
  useEffect(() => {
    // Set a timeout if you want a delay or remove it for an immediate redirect
    const timer = setTimeout(() => {
      window.location.href = 'https://noplaybook.beehiiv.com/';
    }, 3000); // Redirect after 3 second

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  return (
    <div className="creates-redirect-message">
      <p>Redirecting you to Will's newsletter. Consider subscribing to get notified of the latest posts...</p>

      {/* Optional: Add a loader or any content you want to show before redirect */}
    </div>
  );
}

export default RedirectPage;
