import logo from './logo.svg';
import './styles.css';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import CreatesPage from './components/CreatesPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <Router>
    <div className="App">
      <Header />
      <main>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/creates" element={<CreatesPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      </main>
      <Footer />
    </div>
  </Router>

  );
}

export default App;
