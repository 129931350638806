// Services.js
import React from 'react';

const services = [
  {
    title: "AI-led Transformation Strategy",
    description: "Guiding your organization through a comprehensive strategy for AI implementation and integration."
  },
  {
    title: "Diagnose AI Readiness",
    description: "Assessing your current infrastructure and capabilities to leverage AI technologies effectively."
  },
  {
    title: "Architecting AI enablers and investment options",
    description: "Designing the foundational elements and strategic investments required for AI success."
  },
  {
    title: "Identify high impact AI opportunities and create benefit plans",
    description: "Discovering and planning for AI initiatives that offer significant value and competitive advantage."
  },
  {
    title: "Drive AI adoption and capability maturity",
    description: "Ensuring your team is prepared and equipped to implement and evolve with AI technologies."
  },
];

function Services() {
  return (
    <div className="services-container">
      {services.map((service, index) => (
        <div className="service-card" key={index}>
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  );
}

export default Services;
